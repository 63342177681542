import { FC } from 'react'
import useWorkPlaces from '@hooks/useWorkPlaces'
import WorkPlacesTable from '@pages/WorkPlaces/WorkPlacesTable'
import { MainFields } from '@modals/JobConditionAssessmentModal/enums'
import { PositionDetailed } from '@models/position'
import { createInitFilter } from '@utils/filters'

type Props = {
  position: PositionDetailed
}

const Sout: FC<Props> = ({ position }) => {
  const { data } = useWorkPlaces({
    initFilters: createInitFilter('jobPosition', position['@id']),
  })
  const { items = [], length = 0 } = data || {}

  return (
    <WorkPlacesTable
      list={items}
      length={length}
      disabledColumns={[MainFields.POSITION, MainFields.DIVISION]}
    />
  )
}

export default Sout
