import styled from '@emotion/styled'
import Text from '@organisms/Text'

export const Description = styled(Text)``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const DownloadWrapper = styled.div`
  display: flex;
`
