import { FC } from 'react'
import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import {
  checkboxField,
  textAreaField,
} from '@templates/AddNewItemModal/templates'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import { ContentBeforeItemsWrapper } from '@templates/AddNewItemModal/Content/styled'
import { JobConditionAssessmentWarrantyTypeOption } from '@models/jobConditionAssessment'

const WIDTH_MULTIPLIER = 3

const renderBlock = (lineTitle: string, index: number) => {
  const line11: Field[] = [
    checkboxField({
      name: `jobConditionAssessmentWarranties.${index}.actual`,
      label: 'Требуемое',
    }),
    checkboxField({
      name: `jobConditionAssessmentWarranties.${index}.required`,
      label: 'Фактическое',
    }),
  ]
  const line12: Field[] = [
    textAreaField({
      name: `jobConditionAssessmentWarranties.${index}.reason`,
      placeholder: 'Основание',
      label: 'Основание',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  return (
    <>
      <LineFields
        fields={line11}
        lineTitle={lineTitle}
        lineTitleFontSize={18}
      />
      <LineFields fields={line12} />
    </>
  )
}

const Warranties: FC = () => {
  const { control } = useFormContext()

  const { fields } = useFieldArray({
    name: 'jobConditionAssessmentWarranties',
    control,
  })

  return (
    <ExternalLineWrapper>
      <ContentBeforeItemsWrapper>
        <DescriptionWrapper>
          Гарантии и компенсации, предоставляемые работнику (работникам),
          занятым на данном рабочем месте.
        </DescriptionWrapper>
      </ContentBeforeItemsWrapper>
      {fields.map((field, index) => {
        const typedField = field as unknown as {
          id: string
        } & JobConditionAssessmentWarrantyTypeOption
        return (
          <div key={typedField.id}>{renderBlock(typedField.title, index)}</div>
        )
      })}
    </ExternalLineWrapper>
  )
}

export default Warranties
