import { isNil } from 'lodash'

const isNothing = (value: any) => {
  const valueForCheck = Array.isArray(value) ? value[0] : value

  return valueForCheck === '' || isNil(valueForCheck)
}

const paramsSerializerToArray = (
  params: Record<string, any>,
  acc: string[] = [],
  keyPrefix = '',
  valuePrefix = ''
): string[] => {
  for (const key in params) {
    const value = params[key]

    if (isNothing(value)) {
      continue
    }

    const resultKey = keyPrefix + key

    if (Array.isArray(value)) {
      for (const arrItem of value) {
        if (Array.isArray(arrItem)) {
          for (const item of arrItem) {
            acc.push(resultKey + '%5B%5D=' + item)
          }
        } else {
          acc.push(resultKey + '%5B%5D=' + arrItem)
        }
      }
    } else if (typeof value === 'object') {
      acc.push(...paramsSerializerToArray(value, acc, `${key}%5B`, '%5D'))
    } else {
      acc.push(resultKey + valuePrefix + '=' + value)
    }
  }

  return Array.from(new Set(acc))
}

const paramsSerializer = (params: Record<string, any>): string => {
  return paramsSerializerToArray(params).join('&')
}

export default paramsSerializer
