import styled from '@emotion/styled'

export const StyledLabel = styled.h2`
  color: #7f7f7f;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  padding: 0;
  margin: 0;
`

export const StyledTable = styled.table`
  th:nth-child(1),
  th:nth-child(4) {
    width: 30%;
  }

  th:nth-child(2),
  th:nth-child(3) {
    width: 5%;
  }
`
