import { UseFormReturn } from 'react-hook-form'
import useJobFilters from '@hooks/useJobFilters'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'

const usePositionFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division } = useJobFilters(methods)

  const filters: FiltersProps = [
    {
      filterType: FilterTypes.HIDDEN,
      fieldName: 'id',
    },
  ]

  if (company) {
    filters.push({
      ...company,
      fieldNameForSubmit: 'subdivision.companyBranch.company',
    })
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'subdivision.companyBranch',
    })
  }

  if (division) {
    filters.push({
      ...division,
      fieldNameForSubmit: 'subdivision',
    })
  }

  if (window.ENABLE_SOUT) {
    filters.push({
      filterType: FilterTypes.CHECKBOX,
      // TODO
      fieldName: 'check',
      label: 'Нет карты СОУТ',
    })
  }

  return filters
}

export default usePositionFilters
