import React, { FC } from 'react'
import { WorkPlaceDetailed } from '@models/workPlace'
import AccordionBodyField from '@organisms/AccordionBodyField'
import { InSquareValue } from '@pages/WorkPlaces/WorkPlacesTable'

const ClassAccordionField: FC<{ label: string; value: string | undefined }> = ({
  label,
  value,
}) => (
  <AccordionBodyField
    label={label}
    value={value ? <InSquareValue value={value} /> : undefined}
  />
)

type Props = {
  workPlace: WorkPlaceDetailed
}

const Classes: FC<Props> = ({ workPlace }) => {
  const { jobConditionAssessment } = workPlace
  const {
    chemicalClass,
    biologyClass,
    aerosolsFactor,
    noiseFactor,
    infrasoundFactor,
    ultrasoundAirFactor,
    generalVibrationFactor,
    localVibrationFactor,
    nonIonizingRadiationFactor,
    ionizingRadiationFactor,
    microclimateParametersFactor,
    lightEnvironmentParametersFactor,
    laborProcessSeverityFactor,
    laborProcessTensionFactor,
    workingConditionFinalClass,
  } = jobConditionAssessment || {}

  return (
    <>
      <ClassAccordionField label="Химический" value={chemicalClass} />
      <ClassAccordionField label="Биологический" value={biologyClass} />
      <ClassAccordionField
        label="Аэрозоли преимущественно фиброгенного действия"
        value={aerosolsFactor}
      />
      <ClassAccordionField label="Шум" value={noiseFactor} />
      <ClassAccordionField label="Инфразвук" value={infrasoundFactor} />
      <ClassAccordionField
        label="Ультразвук воздушный"
        value={ultrasoundAirFactor}
      />
      <ClassAccordionField
        label="Вибрация общая"
        value={generalVibrationFactor}
      />
      <ClassAccordionField
        label="Вибрация локальная"
        value={localVibrationFactor}
      />
      <ClassAccordionField
        label="Неионизирующие излучения"
        value={nonIonizingRadiationFactor}
      />
      <ClassAccordionField
        label="Ионизирующие излучения"
        value={ionizingRadiationFactor}
      />
      <ClassAccordionField
        label="Параметры микроклимата"
        value={microclimateParametersFactor}
      />
      <ClassAccordionField
        label="Параметры световой среды"
        value={lightEnvironmentParametersFactor}
      />
      <ClassAccordionField
        label="Тяжесть трудового процесса"
        value={laborProcessSeverityFactor}
      />
      <ClassAccordionField
        label="Напряженность трудового процесса"
        value={laborProcessTensionFactor}
      />
      <ClassAccordionField
        label="Итоговый класс УТ"
        value={workingConditionFinalClass}
      />
    </>
  )
}

export default Classes
