import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const TableWrapper = styled.div`
  max-width: 100vw;
  overflow-y: auto;
  padding: ${() => (isMobile ? '0 5px' : '0 20px')};
`

export const VerticalTextWrapper = styled.div`
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  max-height: 150px;
  width: 100%;
  height: 100%;
`

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SettingsRow = styled.label`
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  align-items: flex-start;
  cursor: pointer;
`

export const ActionsRowWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
`

export const WorkPlaceNumber = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
`

export const Declared = styled.span`
  font-family: Gilroy;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
`

export const ColoredSquare = styled.div<{ color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
`
