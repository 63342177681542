import { FC, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { notification } from 'antd'
import useFormProcessor from '@hooks/useFormProcessor'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import { useUploadRiskMapPositionsDictionaryMutation } from '@services/riskMapJobPositions'
import { File } from '@models/file'
import FieldComponent from '@templates/Field'
import { fileField } from '@templates/AddNewItemModal/templates'
import PrimaryButton from '@organisms/PrimaryButton'
import { StyledContentBlock } from '../styled'
import {
  StyledSaveButtonWrapper,
  StyledExampleButtonWrapper,
  Wrapper,
} from './styled'

export type FormValues = {
  file: File
}

const RiskMaps: FC = () => {
  const [upload, { error, isLoading, isSuccess }] =
    useUploadRiskMapPositionsDictionaryMutation()

  const methods = useFormProcessor({
    apiErrors: error,
  })

  const handleSubmitForm = (form: FormValues) => {
    upload({
      file: form.file['@id'],
    })
  }

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message:
          'Опасности по должностям будут созданы в ближайшее время. По окончании придёт уведомление на платформе.',
      })
    }
  }, [isSuccess])

  return (
    <StyledContentBlock>
      <FormProvider {...methods}>
        <Wrapper>
          <FieldComponent
            {...fileField({
              name: 'file',
              label: 'Опасности по должностям',
              isRequired: true,
              formats: ['xls', 'xlsx'],
              maxSizeMegaBytes: 50,
            })}
          />
          <StyledExampleButtonWrapper>
            <DownloadExampleButton
              templateLink="./risk_map_positions_upload.xlsx"
              title="Опасности-по должности.xlsx"
            />
          </StyledExampleButtonWrapper>
          <StyledSaveButtonWrapper>
            <PrimaryButton
              onClick={methods.handleSubmit(handleSubmitForm)}
              isLoading={isLoading}
            >
              Сохранить
            </PrimaryButton>
          </StyledSaveButtonWrapper>
        </Wrapper>
      </FormProvider>
    </StyledContentBlock>
  )
}

export default RiskMaps
