import { FC } from 'react'
import DeleteButton from '@templates/DeleteButton'
import { useDeleteWorkPlaceMutation } from '@services/workPlaces'
import { DataItem } from '../index'

type Props = {
  record: DataItem
}

const DeleteWorkPlace: FC<Props> = ({ record: { id } }) => {
  const [remove, { isLoading }] = useDeleteWorkPlaceMutation()

  const handleClick = () => {
    void remove(id)
  }

  return <DeleteButton isLoading={isLoading} onClick={handleClick} />
}

export default DeleteWorkPlace
