import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { compact } from 'lodash'
import { AccordionBodyProps } from '@organisms/Accordion'
import Tabs from '@organisms/Tabs'
import EditModal from '@templates/EditModal'
import RiskMapsBodyList from '@templates/RiskMapsBodyList'
import PositionModal from '@modals/PositionModal'
import {
  useDeletePositionMutation,
  useGetPositionQuery,
} from '@services/positions'
import DeleteButton from '@templates/DeleteButton'
import { createInitFilter } from '@utils/filters'
import Sout from './Sout'
import { FooterWrapper } from './styled'

type Props = {
  id: number
} & AccordionBodyProps

const Body: FC<Props> = ({ id, onChangeSize, toggleLoading }) => {
  const [active, setActive] = useState(0)

  const { data, isSuccess } = useGetPositionQuery(id)
  const [deletePosition, deleteData] = useDeletePositionMutation()

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (isSuccess) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [isSuccess])

  const handleTabChange = () => {
    setTimeout(onChangeSize, 500)
  }

  if (!data) return null

  return (
    <>
      <Tabs
        active={active}
        setActive={setActive}
        onTabChange={handleTabChange}
        tabSize="small"
        items={compact([
          {
            id: 'riskMaps',
            title: 'Карты рисков',
            children: (
              <RiskMapsBodyList
                initFilters={createInitFilter('jobPosition', data?.['@id'])}
                onLoaded={onChangeSize}
                withoutReviewed
              />
            ),
          },
          window.ENABLE_SOUT && {
            title: 'СОУТ',
            id: 'sout',
            children: <Sout position={data} />,
          },
        ])}
      />
      <FooterWrapper>
        <EditModal
          ModalContent={PositionModal}
          modalProps={{ position: data }}
        />
        <DeleteButton
          {...deleteData}
          onClick={() => {
            deletePosition(id)
          }}
          deleteDescription="Вы собираетесь удалить должность! При подтверждении будут удалены и все сотрудники в этой должности. Восстановить удаленную информацию будет невозможно! Вы уверены, что хотите удалить ВСЮ должность?"
        />
      </FooterWrapper>
    </>
  )
}

export default Body
