import * as React from 'react'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Routes } from '@enums/Routes'
import { ProgressStatuses } from '@enums/ProgressStatuses'
import InfoField from '@organisms/InfoField'
import { Option } from '@organisms/Select'
import AccordionBodyFieldWithTabs from '../AccordionBodyFieldWithTabs'

type Props = {
  label?: string
  isAccordionField?: boolean
  countEmployees: number
  company?: Option
  filial?: Option
  division?: Option
  position?: Option
  statuses?: ProgressStatuses[]
  educationId?: number
  reviewedRiskMapRevision?: string
  notReviewedRiskMapRevision?: string
  jobConditionAssessmentReviewed?: string
  jobConditionAssessmentNotReviewed?: string
}

const EmployeesCountField: FC<Props> = ({
  label = 'Количество сотрудников',
  isAccordionField = false,
  countEmployees,
  company,
  filial,
  division,
  position,
  statuses,
  educationId,
  reviewedRiskMapRevision,
  notReviewedRiskMapRevision,
  jobConditionAssessmentReviewed,
  jobConditionAssessmentNotReviewed,
}) => {
  const navigate = useNavigate()

  const queries: string[] = []
  if (company) {
    queries.push(`company=${JSON.stringify(company)}`)
  }
  if (filial) {
    queries.push(`filial=${JSON.stringify(filial)}`)
  }
  if (division) {
    queries.push(`division=${JSON.stringify(division)}`)
  }
  if (position) {
    queries.push(`position=${JSON.stringify(position)}`)
  }
  if (statuses) {
    queries.push(`status=${statuses}`)
  }
  if (educationId) {
    queries.push(`education=${educationId}`)
  }
  if (reviewedRiskMapRevision) {
    queries.push(`reviewedRiskMapRevision=${reviewedRiskMapRevision}`)
  }
  if (notReviewedRiskMapRevision) {
    queries.push(`notReviewedRiskMapRevision=${notReviewedRiskMapRevision}`)
  }
  if (jobConditionAssessmentReviewed) {
    queries.push(
      `jobConditionAssessmentReviewed=${jobConditionAssessmentReviewed}`
    )
  }
  if (jobConditionAssessmentNotReviewed) {
    queries.push(
      `jobConditionAssessmentNotReviewed=${jobConditionAssessmentNotReviewed}`
    )
  }

  const handleClick = () => {
    navigate(
      Routes.EMPLOYEES + (queries.length > 0 ? '?' + queries.join('&') : '')
    )
  }

  if (isAccordionField) {
    return (
      <AccordionBodyFieldWithTabs
        label={label}
        items={[
          {
            id: '1',
            title: countEmployees,
            onClick: handleClick,
          },
        ]}
      />
    )
  }

  return (
    <InfoField onTabClick={handleClick} label={label} value={countEmployees} />
  )
}

export default EmployeesCountField
