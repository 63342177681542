import { FC } from 'react'
import { compact } from 'lodash'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import { Option } from '@organs/Select'
import { MainFields } from '@modals/JobConditionAssessmentModal/enums'
import useJobFields from '@hooks/useJobFields'
import { Field } from '@templates/AddNewItemModal/Content'
import {
  checkboxField,
  numberField,
  textAreaField,
  textField,
} from '@templates/AddNewItemModal/templates'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { CommonWorkPlaceBody, WorkPlaceDetailed } from '@models/workPlace'
import {
  useCreateWorkPlaceMutation,
  useUpdateWorkPlaceMutation,
} from '@services/workPlaces'
import getDefaultValues from './getDefaultValues'

const WIDTH_MULTIPLIER = 4

export type FormValues = {
  workplaceNumber: string
  company?: Option
  filial?: Option
  division?: Option
  position?: Option
  title?: string
  code?: string
  etksEksEdition?: string
  similarWorkplacesCountAndNumbers?: string
  usedProductionEquipment?: string
  usedMaterialsAndRaw?: string
  countOnWorkplace?: string
  countOnSimilarWorkplaces?: string
  womenCount?: string
  under18Count?: string
  disabledCount?: string
}

type Props = {
  isEdit?: boolean
  workPlace?: WorkPlaceDetailed
  handleCloseModal: () => void
}

const WorkPlaceModal: FC<Props> = ({
  isEdit = false,
  workPlace,
  handleCloseModal,
}) => {
  const [post, postData] = useCreateWorkPlaceMutation()
  const [put, putData] = useUpdateWorkPlaceMutation()
  const additionalProps = isEdit ? putData : postData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(workPlace),
  })

  const declared = methods.watch('declared')
  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonWorkPlaceBody = {
      workplaceNumber: form.workplaceNumber,
      company: form.company?.value,
      companyBranch: form.filial?.value,
      subdivision: form.division?.value,
      jobPosition: form.position?.value,
      title: form.title,
      code: form.code,
      declared: !!declared,
      usedProductionEquipment: form.usedProductionEquipment,
      similarWorkplacesCountAndNumbers: form.similarWorkplacesCountAndNumbers,
      etksEksEdition: form.etksEksEdition,
      usedMaterialsAndRaw: form.usedMaterialsAndRaw,
      countOnWorkplace: Number(form.countOnWorkplace),
      countOnSimilarWorkplaces: Number(form.countOnSimilarWorkplaces),
      womenCount: Number(form.womenCount),
      under18Count: Number(form.under18Count),
      disabledCount: Number(form.disabledCount),
    }

    if (isEdit) {
      if (workPlace) {
        put({
          ...commonForm,
          id: workPlace.id,
        })
      }
    } else {
      post(commonForm)
    }
  }
  const { company, filial, division, position } = useJobFields(methods)

  const line1: Field[] = compact([
    company?.(),
    filial?.(),
    division?.(),
    position?.(),
  ])

  const line2: Field[] = [
    textField({
      label: 'Номер рабочего места',
      name: MainFields.WORK_PLACE_NUMBER,
      placeholder: 'Номер рабочего места',
      isRequired: true,
    }),
    textField({
      label: 'Наименование профессии (должности) работника',
      name: MainFields.TITLE,
      placeholder: 'Наименование профессии (должности) работника',
    }),
    textField({
      label: 'Код профессии (должности)',
      name: MainFields.CODE,
      placeholder: 'Код профессии (должности)',
    }),
    textField({
      label: 'Количество и номера аналогичных рабочих мест',
      name: MainFields.SIMILAR_WORKPLACES_COUNT_AND_NUMBERS,
      placeholder: 'Количество и номера аналогичных рабочих мест',
    }),
  ]

  const line3: Field[] = [
    checkboxField({
      name: MainFields.DECLARED,
      label: 'Декларируемое рабочее место',
    }),
  ]

  const line4: Field[] = [
    textAreaField({
      name: MainFields.EKTS_EDITION,
      placeholder: 'Выпуск ЕТКС, ЕКС',
      label: 'Выпуск ЕТКС, ЕКС',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line5: Field[] = [
    textAreaField({
      name: MainFields.USED_PRODUCTION_EQUIPMENT,
      placeholder: 'Используемое производственное оборудование',
      label: 'Используемое производственное оборудование',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line6: Field[] = [
    textAreaField({
      name: MainFields.USED_MATERIALS_AND_RAW,
      placeholder: 'Используемые материалы и сырье',
      label: 'Используемые материалы и сырье',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line7: Field[] = [
    numberField({
      name: MainFields.ON_WORK_PLACE,
      label: 'На рабочем месте',
      placeholder: 'На рабочем месте',
    }),
    numberField({
      name: MainFields.ON_SIMILAR_WORK_PLACE,
      label: 'На всех аналогичных местах',
      placeholder: 'На всех аналогичных местах',
    }),
    numberField({
      name: MainFields.WOMEN,
      label: 'Из них женщин',
      placeholder: 'Из них женщин',
    }),
    numberField({
      name: MainFields.UNDER_18,
      label: 'Из них лиц до 18 лет',
      placeholder: 'Из них лиц до 18 лет',
    }),
  ]

  const line8: Field[] = [
    numberField({
      name: MainFields.DISABLED,
      label: 'Из них инвалидов',
      placeholder: 'Из них инвалидов',
    }),
  ]

  return (
    <AddNewItemModalLayout
      {...additionalProps}
      handleCloseModal={handleCloseModal}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      titlePostfix="рабочее место"
      withStopPropagation
    >
      <ExternalLineWrapper>
        <LineFields fields={line1} />
        <LineFields fields={line2} lineTitle="Рабочее место" />
        <LineFields fields={line3} />
        <LineFields fields={line4} />
        <LineFields fields={line5} />
        <LineFields fields={line6} />
        <LineFields fields={line7} lineTitle="Численность работающих" />
        <LineFields fields={line8} />
      </ExternalLineWrapper>
    </AddNewItemModalLayout>
  )
}

export default WorkPlaceModal
