import { FC, ReactNode } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

type Props = {
  children: ReactNode
  onOutsideClick: (e: MouseEvent) => void
}

const OutsideClick: FC<Props> = ({ children, onOutsideClick }) => {
  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const classNames = e.target.className
        if (
          classNames?.includes?.('popover') ||
          classNames?.includes?.('ant-picker')
        ) {
          return
        }

        onOutsideClick(e)
      }}
    >
      {children}
    </OutsideClickHandler>
  )
}

export default OutsideClick
