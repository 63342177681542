import { FC, ReactNode } from 'react'
import { useToggle } from 'react-use'
import Modal from '@organisms/Modal'
import AddButton from '@organisms/AddButton'
import ButtonStyle from '@enums/ButtonStyle'
import { LinkButton, Wrapper } from './styled'

type Props = {
  ModalContent: FC<{ handleCloseModal: () => void }>
  addButtonTitle?: string
  buttonStyle?: ButtonStyle
  id?: string
  isLinkStyle?: boolean
  leftIcon?: ReactNode
}

const AddButtonComponent: FC<Props> = ({
  ModalContent,
  addButtonTitle,
  buttonStyle = ButtonStyle.PRIMARY,
  id = 'add-button',
  isLinkStyle = false,
  leftIcon,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  const handleCloseModal = () => {
    toggleVisible(false)
  }

  return (
    <Wrapper>
      {isLinkStyle ? (
        <LinkButton onClick={toggleVisible} id={id}>
          {addButtonTitle}
        </LinkButton>
      ) : (
        <AddButton
          onClick={toggleVisible}
          buttonStyle={buttonStyle}
          id={id}
          leftIcon={leftIcon}
        >
          {addButtonTitle}
        </AddButton>
      )}
      <Modal onModalClose={handleCloseModal} visible={visible}>
        <ModalContent handleCloseModal={handleCloseModal} />
      </Modal>
    </Wrapper>
  )
}

export default AddButtonComponent
