import { FC } from 'react'
import { useToggle } from 'react-use'
import { ReactComponent as BellIcon } from '@interface-images/bell.svg'
import { useGetUnreadNotificationsCountQuery } from '@services/notifications'
import { POOLING_NOTIFICATIONS_INTERVAL } from '@const/pooling'
import OutsideClick from '@organisms/OutsideClick'
import { Container, Wrapper } from './styled'
import Counter from './Counter'
import List from './List'

const Bell: FC = () => {
  const { data: unreadNotificationsCount = 0 } =
    useGetUnreadNotificationsCountQuery(undefined, {
      pollingInterval: POOLING_NOTIFICATIONS_INTERVAL,
    })

  const [listIsVisible, toggleListIsVisible] = useToggle(false)

  return (
    <OutsideClick onOutsideClick={() => toggleListIsVisible(false)}>
      <Container>
        <Wrapper onClick={toggleListIsVisible}>
          <BellIcon />
          <Counter length={unreadNotificationsCount} />
        </Wrapper>
        {listIsVisible && (
          <List
            listIsVisible={listIsVisible}
            toggleListIsVisible={toggleListIsVisible}
          />
        )}
      </Container>
    </OutsideClick>
  )
}

export default Bell
