import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import { File } from '@models/file'
import AddNewItemModal from '@templates/AddNewItemModal'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import { useAddExternalPassesMutation } from '@services/employeeEducationDestination'
import FileErrorComponent from '@templates/FileErrorComponent'
import useUploadExternalEducationsFields from './useUploadExternalEducationsFields'

type FormValues = {
  file: File
}

type Props = {
  handleCloseModal: () => void
  filialUri: string
}

const UploadExternalEducationsModal: FC<Props> = ({
  handleCloseModal,
  filialUri,
}) => {
  const fields = useUploadExternalEducationsFields()

  const [uploadEducations, uploadData] = useAddExternalPassesMutation()

  const methods = useFormProcessor({
    apiErrors: uploadData.error,
    defaultValues: undefined,
  })

  const handleSubmitForm = (form: FormValues) => {
    uploadEducations({
      companyBranch: filialUri,
      file: form.file?.['@id'],
    })
  }

  return (
    <AddNewItemModal
      {...uploadData}
      onSubmitForm={handleSubmitForm}
      contentBeforeFields={
        <DownloadExampleButton
          templateLink="/import_external_educations.xlsx"
          title="Школа экспертов — Загрузка прошлых прохождений"
          description="Добавьте файлы .xls, .xlsx, составленные по образцу"
        />
      }
      methods={methods}
      customFullTitle="Загрузите файл с данными о прошлых прохождениях сотрудника филиала"
      successContentAddTitle="Вы добавили обучения"
      fields={fields}
      isEdit={false}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
    />
  )
}

export default UploadExternalEducationsModal
