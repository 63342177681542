import * as React from 'react'
import { FC } from 'react'
import { useToggle } from 'react-use'
import Modal from '@atoms/Modal'
import JobConditionAssessmentModal from '@modals/JobConditionAssessmentModal'
import { StyledSubtitle } from '@pages/Auth/styled'
import { Wrapper } from '@pages/Auth/AuthBlock/InitForm/styled'
import BlueBorderCard from '@organs/BlueBorderCard'
import WorkPlaceModal from '@modals/WorkPlaceModal'

type Props = {
  handleCloseModal: () => void
}

const CreateWorkPlaceModal: FC<Props> = ({ handleCloseModal }) => {
  const [withMapVisible, toggleWithMapVisible] = useToggle(false)
  const [withoutMapVisible, toggleWithoutMapVisible] = useToggle(false)

  return (
    <div>
      <StyledSubtitle>Есть ли карта СОУТ для рабочего места?</StyledSubtitle>
      <Wrapper>
        <BlueBorderCard
          title="Есть карта СОУТ"
          onClick={toggleWithMapVisible}
        />
        <BlueBorderCard
          title="Нет карты СОУТ"
          onClick={toggleWithoutMapVisible}
        />
      </Wrapper>
      <Modal onModalClose={toggleWithMapVisible} visible={withMapVisible}>
        <JobConditionAssessmentModal handleCloseModal={handleCloseModal} />
      </Modal>
      <Modal onModalClose={toggleWithoutMapVisible} visible={withoutMapVisible}>
        <WorkPlaceModal handleCloseModal={handleCloseModal} />
      </Modal>
    </div>
  )
}

export default CreateWorkPlaceModal
