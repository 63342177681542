import { FC, MouseEventHandler } from 'react'
import { useGetWorkPlaceQuery } from '@services/workPlaces'
import EditModal from '@templates/EditModal'
import JobConditionAssessmentModal from '@modals/JobConditionAssessmentModal'
import WorkPlaceModal from '@modals/WorkPlaceModal'
import { WorkPlaceOption } from '@models/workPlace'

type Props = {
  record: WorkPlaceOption
  CustomEditComponent?: FC<{ onClick: MouseEventHandler; disabled?: boolean }>
}

const EditWorkPlace: FC<Props> = ({ record: { id }, CustomEditComponent }) => {
  const { data, isLoading } = useGetWorkPlaceQuery(id)

  return (
    <EditModal
      isLoading={isLoading}
      ModalContent={
        data?.jobConditionAssessment
          ? JobConditionAssessmentModal
          : WorkPlaceModal
      }
      modalProps={{ workPlace: data }}
      CustomEditComponent={CustomEditComponent}
    />
  )
}

export default EditWorkPlace
