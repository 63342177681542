import { FC, useEffect } from 'react'
import { usePutCourseMutation } from '@services/courses'
import { CourseDetailed, UpdateCourseBody } from '@models/course'
import CloseButton from '@organisms/CloseButton'
import DoneButton from '@organisms/DoneButton'

type Props = {
  course: CourseDetailed
}

const PublishButton: FC<Props> = ({ course }) => {
  const [updateCourse, { isLoading, isSuccess, reset }] = usePutCourseMutation()

  const handleClick = () => {
    const body: UpdateCourseBody = {
      id: course.id,
      title: course.title,
      type: course.type,
      subType: course.subType,
      hoursCount: course.hoursCount,
      published: !course.published,
      courseCategory: course.courseCategory['@id'],
      descriptionLink: course.descriptionLink || undefined,
      free: course.free,
      safeProgram: course.safeProgram,
      company: course.company?.['@id'] || null,
      companyBranch: course.companyBranch?.['@id'] || null,
      ministryLaborId: course.ministryLaborId || undefined,
      electricalSafetyFirstGroup: course.electricalSafetyFirstGroup,
    }
    updateCourse(body)
  }

  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess])

  const Component = course.published ? CloseButton : DoneButton
  const buttonText = course.published
    ? 'Снять с публикации'
    : 'Опубликовать курс'

  return (
    <Component isLoading={isLoading} onClick={handleClick}>
      {buttonText}
    </Component>
  )
}

export default PublishButton
