import { WorkPlaceDetailed } from '@models/workPlace'
import { FormValues } from './index'

const getDefaultValues = (
  workPlace: WorkPlaceDetailed | undefined
): Partial<FormValues> => {
  const result: Partial<FormValues> = {}

  if (!workPlace) return result

  return {
    ...result,
    ...workPlace,
    company: workPlace.company
      ? {
          label: workPlace.company.title,
          value: workPlace.company['@id'],
        }
      : undefined,
    filial: workPlace.companyBranch
      ? {
          label: workPlace.companyBranch.title,
          value: workPlace.companyBranch['@id'],
        }
      : undefined,
    division: workPlace.subdivision
      ? {
          label: workPlace.subdivision.title,
          value: workPlace.subdivision['@id'],
        }
      : undefined,
    position: workPlace.jobPosition
      ? {
          label: workPlace.jobPosition.title,
          value: workPlace.jobPosition['@id'],
        }
      : undefined,
    countOnWorkplace: String(workPlace.countOnWorkplace),
    countOnSimilarWorkplaces: String(workPlace.countOnSimilarWorkplaces),
    womenCount: String(workPlace.womenCount),
    under18Count: String(workPlace.under18Count),
    disabledCount: String(workPlace.disabledCount),
  }
}

export default getDefaultValues
