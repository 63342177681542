import { FC, useState } from 'react'
import { compact } from 'lodash'
import { useGetWorkPlaceQuery } from '@services/workPlaces'
import Loader from '@organisms/Loader'
import Tabs from '@organisms/Tabs'
import Main from './Main'
import Classes from './Classes'
import Warranties from './Warranties'
import Reviews from './Reviews'

type Props = {
  id: number
}

const ExpandedWorkPlaceRow: FC<Props> = ({ id }) => {
  const [active, setActive] = useState(0)

  const { isLoading, data } = useGetWorkPlaceQuery(id)

  if (isLoading) return <Loader isLoading />

  if (!data) return <h3>Что-то пошло не так</h3>

  return (
    <Tabs
      tabSize="small"
      items={compact([
        {
          id: '1',
          title: 'Основные',
          children: <Main workPlace={data} />,
        },
        data.jobConditionAssessment && {
          id: '2',
          title: 'Классы',
          children: <Classes workPlace={data} />,
        },
        data.jobConditionAssessment && {
          id: '3',
          title: 'Гарантии и компенсации',
          children: <Warranties workPlace={data} />,
        },
        data.jobConditionAssessment && {
          id: '4',
          title: 'Ознакомления',
          children: <Reviews workPlace={data} />,
        },
      ])}
      active={active}
      setActive={setActive}
    />
  )
}

export default ExpandedWorkPlaceRow
