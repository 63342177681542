import * as React from 'react'
import { FC, ReactNode } from 'react'
import { add, isAfter } from 'date-fns'
import Table, { Column } from '@organisms/Table'
import { ListPageLayoutListProps } from '@templates/ListPageLayout'
import {
  DownCircleFilled,
  DownloadOutlined,
  SettingFilled,
  UpCircleFilled,
} from '@ant-design/icons'
import ButtonStyle from '@enums/ButtonStyle'
import StyledButton from '@organs/StyledButton'
import { ActionsWrapper } from '@templates/ListPageLayout/Header/styled'
import Expandable from '@organisms/Expandable'
import CheckboxNew from '@atoms/CheckboxNew'
import GrayButton from '@organisms/GrayButton'
import { WorkPlaceOption } from '@models/workPlace'
import useLocalStorage from '@hooks/useLocalStorage'
import {
  ClassesFields,
  MainFields,
} from '@modals/JobConditionAssessmentModal/enums'
import DeleteWorkPlace from '@pages/WorkPlaces/WorkPlacesTable/DeleteWorkPlace'
import EditWorkPlace from '@pages/WorkPlaces/WorkPlacesTable/EditWorkPlace'
import { formatServerDate } from '@utils/date'
import { Tab } from '@organisms/Tabs'
import Link, { LinkStyle } from '@organisms/Link'
import {
  ActionsRowWrapper,
  ColoredSquare,
  Declared,
  SettingsRow,
  SettingsWrapper,
  TableWrapper,
  VerticalTextWrapper,
  WorkPlaceNumber,
} from './styled'
import ExpandedWorkPlaceRow from './ExpandedWorkPlaceRow'

const VerticalText: FC<{ children: string }> = ({ children }) => (
  <VerticalTextWrapper>{children}</VerticalTextWrapper>
)

export const InSquareValue: FC<{ value: string | undefined }> = ({ value }) => {
  if (!value) return null

  const num = Number(value)

  return (
    <ColoredSquare color={num > 3 ? '#D5FAD1' : '#FBD0D0'}>
      {value}
    </ColoredSquare>
  )
}

const COLUMNS = [
  {
    title: 'Общие данные',
    children: [
      {
        title: '№ РМ',
        dataIndex: MainFields.WORK_PLACE_NUMBER,
      },
      {
        title: 'Наименование профессии (должности) работника',
        dataIndex: MainFields.TITLE,
      },
      {
        title: '№ карты СОУТ',
        dataIndex: MainFields.CARD_NUMBER,
      },
      {
        title: 'Дата карты',
        dataIndex: MainFields.DATE,
      },
      {
        title: 'Подразделение',
        dataIndex: MainFields.DIVISION,
      },
      {
        title: 'Должность',
        dataIndex: MainFields.POSITION,
      },
    ],
  },
  {
    title: 'Классы',
    verticalChildren: true,
    children: [
      {
        title: 'Итоговый класс УТ',
        dataIndex: ClassesFields.TOTAL_CLASS,
      },
      {
        title: 'Химический',
        dataIndex: ClassesFields.CHEMICAL,
      },
      {
        title: 'Биологический',
        dataIndex: ClassesFields.BIOLOGICAL,
      },
      {
        title: 'Аэрозоли преимущественно фиброгенного действия',
        dataIndex: ClassesFields.AEROSOLS,
      },
      {
        title: 'ШУМ',
        dataIndex: ClassesFields.NOISE,
      },
      {
        title: 'Инфразвук',
        dataIndex: ClassesFields.INFRASOUND,
      },
      {
        title: 'Ультразвук воздушный',
        dataIndex: ClassesFields.ULTRASOUND,
      },
      {
        title: 'Вибрация общая',
        dataIndex: ClassesFields.GENERAL_VIBRATION,
      },
      {
        title: 'Вибрация локальная',
        dataIndex: ClassesFields.LOCAL_VIBRATION,
      },
      {
        title: 'Неионизирующие излучения',
        dataIndex: ClassesFields.NON_ION_RADIATION,
      },
      {
        title: 'Ионизирующие излучения',
        dataIndex: ClassesFields.ION_RADIATION,
      },
      {
        title: 'Параметры микроклимата',
        dataIndex: ClassesFields.MICROCLIMATE,
      },
      {
        title: 'Параметры световой среды',
        dataIndex: ClassesFields.LIGHT,
      },
      {
        title: 'Тяжесть трудового процесса',
        dataIndex: ClassesFields.LABOR_SEVERITY,
      },
      {
        title: 'Напряженность трудового процесса',
        dataIndex: ClassesFields.LABOR_TENSION,
      },
    ],
  },
]

const getColumnToRender = (
  inactiveColumns: string[],
  disabledColumns?: Array<keyof DataItem>
): Column[] =>
  COLUMNS.reduce((acc, item) => {
    if (inactiveColumns.includes(item.title)) return acc

    const activeChildren = item.children
      .filter((item) =>
        disabledColumns
          ? !disabledColumns.includes(item.dataIndex as keyof DataItem)
          : true
      )
      .filter((item) => !inactiveColumns.includes(item.title))

    acc.push(
      item.verticalChildren
        ? {
            ...item,
            children: activeChildren.map((child: { title: string }) => ({
              ...child,
              title: <VerticalText>{child.title}</VerticalText>,
            })),
          }
        : {
            ...item,
            children: activeChildren,
          }
    )

    return acc
  }, [] as Column[]).filter((item) => item.children && item.children.length > 0)

export type DataItem = {
  key: string
  id: number
  [MainFields.WORK_PLACE_NUMBER]: ReactNode | undefined
  [MainFields.TITLE]: string | undefined
  [MainFields.CARD_NUMBER]: ReactNode | undefined
  [MainFields.DATE]: string | undefined
  [MainFields.DIVISION]: ReactNode | undefined
  [MainFields.POSITION]: ReactNode | undefined
  [ClassesFields.TOTAL_CLASS]: ReactNode | undefined
  [ClassesFields.CHEMICAL]: ReactNode | undefined
  [ClassesFields.BIOLOGICAL]: ReactNode | undefined
  [ClassesFields.AEROSOLS]: ReactNode | undefined
  [ClassesFields.NOISE]: ReactNode | undefined
  [ClassesFields.INFRASOUND]: ReactNode | undefined
  [ClassesFields.ULTRASOUND]: ReactNode | undefined
  [ClassesFields.GENERAL_VIBRATION]: ReactNode | undefined
  [ClassesFields.LOCAL_VIBRATION]: ReactNode | undefined
  [ClassesFields.NON_ION_RADIATION]: ReactNode | undefined
  [ClassesFields.ION_RADIATION]: ReactNode | undefined
  [ClassesFields.MICROCLIMATE]: ReactNode | undefined
  [ClassesFields.LIGHT]: ReactNode | undefined
  [ClassesFields.LABOR_SEVERITY]: ReactNode | undefined
  [ClassesFields.LABOR_TENSION]: ReactNode | undefined
}

type Props = {
  list: WorkPlaceOption[]
  disabledColumns?: Array<keyof DataItem>
} & ListPageLayoutListProps

const WorkPlacesTable: FC<Props> = ({ list, disabledColumns }) => {
  const [inactiveColumnsCookie, setInactiveColumnsCookie] = useLocalStorage(
    'WorkPlacesTable',
    '[]'
  )
  const inactiveColumns = JSON.parse(inactiveColumnsCookie || '[]')
  const setInactiveColumns = (cb: (columns: string[]) => string[]) => {
    setInactiveColumnsCookie(JSON.stringify(cb(inactiveColumns)))
  }

  const tableData: DataItem[] = []
  for (const item of list as WorkPlaceOption[]) {
    const {
      jobConditionAssessment,
      title,
      workplaceNumber,
      subdivision,
      id,
      jobPosition,
      declared,
      status,
    } = item
    const {
      cardNumber,
      date,
      workingConditionFinalClass,
      chemicalClass,
      biologyClass,
      aerosolsFactor,
      noiseFactor,
      infrasoundFactor,
      ultrasoundAirFactor,
      generalVibrationFactor,
      localVibrationFactor,
      nonIonizingRadiationFactor,
      ionizingRadiationFactor,
      microclimateParametersFactor,
      lightEnvironmentParametersFactor,
      laborProcessSeverityFactor,
      laborProcessTensionFactor,
    } = jobConditionAssessment || {}

    tableData.push({
      id,
      key: String(id),
      workplaceNumber: (
        <WorkPlaceNumber>
          <span>{workplaceNumber}</span>
          {declared && <Declared>Декларируемое</Declared>}
          {status === 'archived' && (
            <Tab
              title="Архив"
              tabSize="small"
              id="archive"
              badgeColor="gray"
              withoutHover
            />
          )}
        </WorkPlaceNumber>
      ),
      title,
      cardNumber: (
        <WorkPlaceNumber>
          <span>{cardNumber}</span>
          {date && isAfter(new Date(), add(new Date(date), { years: 5 })) && (
            <Tab
              title="Просрочена"
              tabSize="small"
              id="archive"
              badgeColor="red"
              withoutHover
              promptText="Карта просрочена. Срок действия карты 5 лет"
            />
          )}
        </WorkPlaceNumber>
      ),
      date: date ? formatServerDate(date) : undefined,
      division: subdivision?.title ? (
        subdivision.title
      ) : (
        <EditWorkPlace
          record={item}
          CustomEditComponent={({ onClick }) => (
            <Link
              onClick={onClick}
              text="Заполнить"
              linkStyle={LinkStyle.DEFAULT}
            />
          )}
        />
      ),
      position: jobPosition?.title ? (
        jobPosition.title
      ) : (
        <EditWorkPlace
          record={item}
          CustomEditComponent={({ onClick }) => (
            <Link
              onClick={onClick}
              text="Заполнить"
              linkStyle={LinkStyle.DEFAULT}
            />
          )}
        />
      ),
      workingConditionFinalClass: (
        <InSquareValue value={workingConditionFinalClass} />
      ),
      chemicalClass: <InSquareValue value={chemicalClass} />,
      biologyClass: <InSquareValue value={biologyClass} />,
      aerosolsFactor: <InSquareValue value={aerosolsFactor} />,
      noiseFactor: <InSquareValue value={noiseFactor} />,
      infrasoundFactor: <InSquareValue value={infrasoundFactor} />,
      ultrasoundAirFactor: <InSquareValue value={ultrasoundAirFactor} />,
      generalVibrationFactor: <InSquareValue value={generalVibrationFactor} />,
      localVibrationFactor: <InSquareValue value={localVibrationFactor} />,
      nonIonizingRadiationFactor: (
        <InSquareValue value={nonIonizingRadiationFactor} />
      ),
      ionizingRadiationFactor: (
        <InSquareValue value={ionizingRadiationFactor} />
      ),
      microclimateParametersFactor: (
        <InSquareValue value={microclimateParametersFactor} />
      ),
      lightEnvironmentParametersFactor: (
        <InSquareValue value={lightEnvironmentParametersFactor} />
      ),
      laborProcessSeverityFactor: (
        <InSquareValue value={laborProcessSeverityFactor} />
      ),
      laborProcessTensionFactor: (
        <InSquareValue value={laborProcessTensionFactor} />
      ),
    })
  }

  const handleCheckboxClickCreator = (title: string) => (e: any) => {
    setInactiveColumns((prev) =>
      e.target.checked
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    )
  }

  return (
    <TableWrapper>
      <Table
        columns={getColumnToRender(inactiveColumns, disabledColumns)}
        data={tableData}
        expandable={{
          columnTitle: (
            <Expandable
              desktopLeft={1}
              withoutClose
              Content={() => (
                <SettingsWrapper>
                  <StyledButton
                    autoWidth
                    leftIcon={<SettingFilled />}
                    buttonStyle={ButtonStyle.PRIMARY}
                  />
                </SettingsWrapper>
              )}
              expandedContent={
                <ActionsWrapper>
                  {COLUMNS.map(({ title, children }) => {
                    return (
                      <div key={title}>
                        <SettingsRow>
                          <CheckboxNew
                            checked={
                              title
                                ? !inactiveColumns.includes(title as string)
                                : true
                            }
                            onChange={handleCheckboxClickCreator(
                              title as string
                            )}
                          />
                          {title}
                        </SettingsRow>
                        <ActionsWrapper tabbed>
                          {children.map((children) => {
                            return (
                              <SettingsRow key={children.dataIndex}>
                                <CheckboxNew
                                  checked={
                                    children.title
                                      ? !inactiveColumns.includes(
                                          children.title as string
                                        )
                                      : true
                                  }
                                  onChange={handleCheckboxClickCreator(
                                    children.title as string
                                  )}
                                />
                                {children.title}
                              </SettingsRow>
                            )
                          })}
                        </ActionsWrapper>
                      </div>
                    )
                  })}
                </ActionsWrapper>
              }
            />
          ),
          expandIcon: (props) => (
            <ActionsRowWrapper>
              <Expandable
                desktopLeft={1}
                withoutClose
                Content={() => (
                  <SettingsWrapper>
                    <StyledButton
                      autoWidth
                      leftIcon={<SettingFilled />}
                      buttonStyle={ButtonStyle.PRIMARY}
                    />
                  </SettingsWrapper>
                )}
                expandedContent={
                  <ActionsWrapper>
                    <EditWorkPlace record={props.record} />
                    <DeleteWorkPlace record={props.record} />
                    <GrayButton leftIcon={<DownloadOutlined />}>
                      В архив
                    </GrayButton>
                  </ActionsWrapper>
                }
              />
              <StyledButton
                autoWidth
                onClick={(e) => props.onExpand(props.record, e)}
                leftIcon={
                  props.expanded ? <UpCircleFilled /> : <DownCircleFilled />
                }
                buttonStyle={ButtonStyle.PRIMARY}
              />
            </ActionsRowWrapper>
          ),
          expandedRowRender: (props) => <ExpandedWorkPlaceRow id={props.id} />,
        }}
      />
    </TableWrapper>
  )
}

export default WorkPlacesTable
