import { FC } from 'react'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import { selectField } from '@templates/AddNewItemModal/templates'
import { Option } from '@organs/Select'
import { useGetJobConditionAssessmentsClassesQuery } from '@services/jobConditionAssesments'
import { ClassesFields } from '@modals/JobConditionAssessmentModal/enums'

const Classes: FC = () => {
  const { data } = useGetJobConditionAssessmentsClassesQuery()
  const classesOptions: Option[] = data?.items || []

  const commonSelectProps = {
    options: classesOptions,
    dropdownWidth: 350,
    isSearchable: false,
  }

  const line1: Field[] = [
    selectField({
      ...commonSelectProps,
      name: ClassesFields.CHEMICAL,
      label: 'Химический',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.BIOLOGICAL,
      label: 'Биологический',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.AEROSOLS,
      label: 'Аэрозоли преимущественно фиброгенного действия',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.NOISE,
      label: 'Шум',
    }),
  ]

  const line2: Field[] = [
    selectField({
      ...commonSelectProps,
      name: ClassesFields.INFRASOUND,
      label: 'Инфразвук',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.ULTRASOUND,
      label: 'Ультразвук воздушный',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.GENERAL_VIBRATION,
      label: 'Вибрация общая',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.LOCAL_VIBRATION,
      label: 'Вибрация локальная',
    }),
  ]

  const line3: Field[] = [
    selectField({
      ...commonSelectProps,
      name: ClassesFields.NON_ION_RADIATION,
      label: 'Неионизирующие излучения',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.ION_RADIATION,
      label: 'Ионизирующие излучения',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.MICROCLIMATE,
      label: 'Параметры микроклимата',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.LIGHT,
      label: 'Параметры световой среды',
    }),
  ]

  const line4: Field[] = [
    selectField({
      ...commonSelectProps,
      name: ClassesFields.LABOR_SEVERITY,
      label: 'Тяжесть трудового процесса',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.LABOR_TENSION,
      label: 'Напряженность трудового процесса',
    }),
    selectField({
      ...commonSelectProps,
      name: ClassesFields.TOTAL_CLASS,
      label: 'Итоговый класс УТ',
    }),
  ]

  return (
    <ExternalLineWrapper>
      <LineFields fields={line1} />
      <LineFields fields={line2} />
      <LineFields fields={line3} />
      <LineFields fields={line4} />
    </ExternalLineWrapper>
  )
}

export default Classes
